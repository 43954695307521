<template>
  <div class="teams-detail-wrapper">
    <div v-if="isLoading" class="team-detail__loader">
      <Loader />
    </div>
    <div v-else>
      <Header
        :showBackButton="true"
        :title="getTeamDetail.name"
        :iconPath="require('@/assets/images/icons/teams-topic-icon.svg')"
        :isThemedIcon="true"
        :themeColor="themeColor"
        :showAlert="showAlert"
        :variant="alertVariant"
        :enableBrowserBack="true"
        alertIconName="info-circle-fill"
      >
        <template v-slot:header-buttons>
          <Button
            variant="outline-primary-vle"
            :pill="true"
            className="text-uppercase"
            v-if="
              getTeamDetail.joined_team_info &&
                getTeamDetail.joined_team_info.id === getTeamDetail.id
            "
            @click="leaveTeam"
          >
            {{ $t("teams.team.leave.alert.primary_action") }}
          </Button>
          <Button
            class="btn-primary mt-2"
            :pill="true"
            className="text-uppercase"
            :disabled="disableJoinTeams"
            @click="joinTeam"
            v-else
          >
            {{ $t("teams.team.join.alert.primary_action") }}
          </Button>
        </template>
        <template v-slot:alert-content>
          <div
            v-if="alertStatus === 'ALREADY_A_MEMBER'"
            class="teams-alert__content"
          >
            {{ $t("teams.team.create.error.subtitle.leave") }}
            <a
              class="c-pointer"
              @click="goToTeamDetails(getTeamDetail.joined_team_info.id)"
              >{{ getTeamDetail.joined_team_info.name }}</a
            >
          </div>
          <!-- fade alert yet to be done -->
          <div
            v-else-if="alertStatus === 'JOIN_TEAM'"
            class="teams-alert__content"
          >
            {{ $t("teams.team.join.alert.title", { s: getTeamDetail.name }) }}
          </div>
          <div
            v-else-if="alertStatus === 'MAX_TEAM_SIZE'"
            class="teams-alert__content"
          >
            {{ $t("teams.details.team_full") }}
          </div>
        </template>
      </Header>
      <div class="team-detail__body">
        <b-row>
          <b-col md="6">
            <div class="teams-description mb-4">
              {{ getTeamDetail.description }}
            </div>
          </b-col>
          <b-col md="6">
            <div
              class="d-flex team-detail__info justify-content-between"
              v-if="getTopicDetail"
            >
              <div
                class="team-detail__info__content team-detail__info__content__topic-name"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/images/icons/teams-topic-icon.svg"
                    width="20"
                    height="auto"
                    alt="topic-icon"
                  />
                  <span :title="getTopicDetail.name">
                    {{ getTopicDetail.name }}
                  </span>
                </div>
              </div>
              <div
                class="team-detail__info__content"
                v-if="getLanguageLabelById(getTeamDetail.language) !== ''"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/images/wrapper-components/course-card/language-icon.svg"
                    alt="language-icon"
                    width="20"
                    height="auto"
                  />
                  <span>
                    {{ getLanguageLabelById(getTeamDetail.language) }}
                  </span>
                </div>
              </div>
              <div
                class="team-detail__info__content team-detail__info__content--members"
              >
                {{
                  $t("teams.teams.x_members", {
                    count: getTeamDetail.member_count
                  })
                }}
              </div>
            </div>
            <div class="team-detail__members-list">
              <PaginationV2
                dataComponentPath="components/Teams/PaginationWrappers/TeamsMemberListWrapper.vue"
                emptyStateComponentPath="components/Teams/PaginationWrappers/TeamsMemberListEmptyWrapper.vue"
                :dataFetchURL="getTeamMembersListURL(courseId, teamId)"
                :authToken="authToken"
                @hasData="displayAllMembersBtn = true"
                :pageSizeValue="6"
              ></PaginationV2>
              <div
                v-if="displayAllMembersBtn === true"
                class="d-flex justify-content-center"
              >
                <Button variant="link" v-b-modal="'team-members-modal'">{{
                  $t("teams.buttons.all_members")
                }}</Button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="team-detail__activity-feed">
        <div class="d-flex justify-content-between">
          <div class="team-detail__activity-feed-title">
            <img
              src="@/assets/images/icons/team-discussion.svg"
              alt="teams"
              width="40"
              height="auto"
            />
            {{ $t("teams.team.discussions.title") }}
          </div>
          <div>
            <Button
              :disabled="addPostDisabled"
              @click="$bvModal.show('add-feed-modal')"
              :pill="true"
              className="text-uppercase"
              >{{ $t("discussions.post.add") }}</Button
            >
          </div>
        </div>
        <div v-if="isTeamDetailsLoaded">
          <PaginationV2
            ref="teamsactivitylistwrapper"
            dataComponentPath="components/Teams/PaginationWrappers/TeamsActivityListWrapper.vue"
            emptyStateComponentPath="components/Teams/PaginationWrappers/TeamsActivityListEmptyWrapper.vue"
            :dataFetchURL="
              getTeamDiscussionsListURL(courseId, discussionTopicId)
            "
            :authToken="authToken"
            :disablePagination="true"
            :additionalProps="teamDiscussionAdditionalProps"
            @hasData="showAllActivityBtn = true"
          ></PaginationV2>
          <div class="d-flex justify-content-center" v-if="showAllActivityBtn">
            <Button
              variant="link"
              @click="showAllActivity"
              class="text-uppercase"
            >
              {{ $t("general.show_all") }}
            </Button>
          </div>
        </div>

        <!-- display members modal for team details  -->

        <b-modal id="team-members-modal" centered size="lg">
          <template #modal-header="{ close }">
            <div class="w-100 text-right c-pointer">
              <img
                src="@/assets/images/close.svg"
                alt="close"
                @click="close"
                width="14"
                height="14"
              />
            </div>
          </template>

          <div class="team-members-modal__body">
            <p class="team-members-modal__heading">
              {{ $t("teams.team.members.title") }}
            </p>
            <div class="w-100 ml-2">
              <PaginationV2
                dataComponentPath="components/Teams/PaginationWrappers/TeamsMemberListWrapper.vue"
                emptyStateComponentPath="components/Teams/PaginationWrappers/TeamsMemberListEmptyWrapper.vue"
                :dataFetchURL="getTeamMembersListURL(courseId, teamId)"
                :authToken="authToken"
                :additionalProps="membersModalAdditionalProps"
              ></PaginationV2>
            </div>
          </div>
          <template slot="modal-footer"><div></div></template>
        </b-modal>

        <AddPostModal
          :topicId="getTeamDetail.discussion_topic_id"
          @postadded="reloadPosts"
        ></AddPostModal>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "./Shared/Header.vue";

import AddPostModal from "./Shared/AddPost.vue";

import TeamsPaginationMixin from "@/components/Teams/PaginationWrappers/mixin.js";
export default {
  mixins: [TeamsPaginationMixin],
  components: { Header, AddPostModal },
  data() {
    return {
      showAlert: false,
      alertStatus: "",
      isTeamDetailsLoaded: false,
      isLoading: true,
      showAllActivityBtn: false,
      displayAllMembersBtn: false,
      membersModalAdditionalProps: {
        modal: true
      }
    };
  },
  computed: {
    ...mapGetters([
      "getTeamDetail",
      "restrictJoinTeams",
      "getTopicDetail",
      "getTopicsList",
      "getTeamsDiscussionList",
      "allLanguage"
    ]),
    themeColor() {
      return this.$route.query.themeColor;
    },
    courseId() {
      return this.$route.params.id;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    teamId() {
      return this.$route.params.teamId;
    },
    discussionTopicId() {
      return this.getTeamDetail.discussion_topic_id;
    },
    teamDiscussionAdditionalProps() {
      return {
        discussionTopicId: this.discussionTopicId
      };
    },
    disableJoinTeams() {
      let isDisabled = false;
      if (this.getTeamDetail.joined_team_info) {
        // check if already a member of another team under this topic
        if (this.getTeamDetail.joined_team_info.id !== this.getTeamDetail.id) {
          isDisabled = true;
          this.setStatus("ALREADY_A_MEMBER");
        }
      } else {
        // check if team size is bigger
        if (
          this.getTopicDetail.max_team_size <= this.getTeamDetail.member_count
        ) {
          isDisabled = true;
          this.setStatus("MAX_TEAM_SIZE");
        }
      }

      return isDisabled;
    },
    alertVariant() {
      let variant = "info";
      if (
        this.alertStatus === "MAX_TEAM_SIZE" ||
        this.alertStatus === "ALREADY_A_MEMBER"
      ) {
        variant = "warning";
      } else if (this.alertStatus === "JOIN_TEAM") {
        variant = "info";
      }
      return variant;
    },
    addPostDisabled() {
      return !(
        this.getTeamDetail.joined_team_info &&
        this.getTeamDetail.joined_team_info.id === this.getTeamDetail.id
      );
    }
  },
  created() {
    this.getTopicDetails();
  },
  methods: {
    getLanguageLabelById(lang) {
      if (lang && lang !== "") {
        let filteredLang = this.allLanguage.filter(l => l.id === lang);
        return filteredLang.length === 1 ? filteredLang[0].name : lang;
      }

      return "";
    },
    reloadPosts() {
      this.$refs.teamsactivitylistwrapper.reload();
    },
    getTopicDetails() {
      let obj = {
        courseId: this.courseId,
        topicId: this.topicId
      };
      this.$store.dispatch("getTopicDetails", obj).then(() => {
        this.getTeamDetails();
      });
    },
    getTeamDetails() {
      this.isLoading = true;
      let obj = {
        teamId: this.teamId,
        topicId: this.topicId,
        courseId: this.courseId
      };
      this.$store.dispatch("getTeamDetail", obj).then(() => {
        this.isTeamDetailsLoaded = true;
        this.isLoading = false;
      });
    },
    joinTeam() {
      let obj = {
        courseId: this.$route.params.id,
        topicId: this.getTeamDetail.topic_id,
        teamId: this.getTeamDetail.id
      };
      this.$store.dispatch("joinTeam", obj).then(() => {
        this.getTeamDetails();
        this.setStatus("JOIN_TEAM");
      });
    },
    hideAlert() {
      this.showAlert = false;
    },
    showAllActivity() {
      this.$router.push({
        name: "ActivityFeed",
        params: { activityId: this.discussionTopicId },
        query: { themeColor: this.themeColor, disabled: this.addPostDisabled }
      });
    },
    setStatus(status) {
      this.alertStatus = status;
      status === "" ? (this.showAlert = false) : (this.showAlert = true);
    },
    leaveTeam() {
      let obj = {
        courseId: this.$route.params.id,
        topicId: this.getTeamDetail.topic_id,
        teamId: this.getTeamDetail.id
      };
      this.$store.dispatch("leaveTeam", obj).then(() => {
        this.getTeamDetails();
        this.setStatus("");
      });
    },
    goToTeamDetails(teamId) {
      this.$router.push({
        name: "TeamDetail",
        params: {
          topicId: this.getTopicDetail.id,
          teamId: teamId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.teams-detail-wrapper {
  text-align: left;
  padding: 60px;
  .team-detail__loader {
    height: 400px;
  }
  .teams-header {
    .hdr-img {
      width: 40px;
      height: 40px;
      background: #e1ddff;
      border-radius: 8px;
      text-align: center;
      padding-top: 6px;
    }
    .card-title__team {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      color: #191c1d;
      padding: 5px 0 10px 15px;
    }
  }
  .team-detail__body {
    padding-bottom: 50px;
    margin-bottom: 30px;
    border-bottom: 1px solid #acacac;
  }
  .team-detail__info {
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    border: 1px solid #acacac;

    .team-detail__info__content {
      color: #191c1d;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;

      img {
        margin-right: 6px;
      }

      &.team-detail__info__content__topic-name {
        width: 40%;
        span {
          @include text-ellipsis;
        }
      }

      &.team-detail__info__content--members {
        color: #44464e;
      }
    }
  }
  .team-detail__members-list {
    background: #fbfdfd;
    border: 1px solid #acacac;
    border-radius: 16px;
    padding-bottom: 15px;
    min-height: calc(270px - 17px);
  }
  .team-detail__activity-feed {
    .team-detail__activity-feed-title {
      color: #191c1d;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      img {
        margin-right: 10px;
      }
    }
  }
  .teams-alert {
    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #0057e0;
      margin: 2px 0 0 10px;
    }
  }
  .teams-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #191c1d;
    word-break: break-word;
  }
  .detail-card {
    border: 1px solid #acacac;
    border-radius: 12px;
    .card-title__detail {
      padding: 3px 0 0 0;
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.1px;
      color: #191c1d;
    }
    .team-avatars {
      ul {
        list-style: none;
        padding-left: 0px;
        li {
          display: inline-block;
          margin-right: 18px;
          span {
            height: 24px;
            width: 24px;
          }
        }
      }
      .team-count {
        border: 1px solid #acacac;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: #ffffff;
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding: 3px 10px;
      }
    }
    .teams-alert-success {
      p {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #00c781;
        margin: 2px 0 0 10px;
      }
    }
  }
}
#team-members-modal {
  .members-header {
    .card-body {
      padding: 10px;
      p {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: #0057e0;
        margin-bottom: 0px;
      }
    }
  }
  .team-members-modal__body {
    .team-members-modal__heading {
      color: #191c1d;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      margin: 0 0 30px 0;
    }
  }
}

// rtl styles
[dir="rtl"] {
  .teams-detail-wrapper {
    .team-detail__info {
      .team-detail__info__content {
        img {
          margin: 0 0 0 6px;
        }
      }
    }
    .team-detail__body {
      .teams-description {
        text-align: right;
      }
    }
  }
}
</style>
