import { render, staticRenderFns } from "./TeamsDetail.vue?vue&type=template&id=27fe3f58&scoped=true"
import script from "./TeamsDetail.vue?vue&type=script&lang=js"
export * from "./TeamsDetail.vue?vue&type=script&lang=js"
import style0 from "./TeamsDetail.vue?vue&type=style&index=0&id=27fe3f58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27fe3f58",
  null
  
)

export default component.exports